import i18n from '@/i18n';

export const formatQuotationStatus = (value) => {
  if (value === 'PENDING') {
    return i18n.t('quotation-status-pending');
  }
  if (value === 'WAITING_MANAGER_APPROVE') {
    return i18n.t('quotation-status-waiting-approve');
  }
  if (value === 'QUOTED') {
    return i18n.t('quotation-status-quoted');
  }
  if (value === 'APPROVED') {
    return 'APPROVED';
  }
  if (value === 'CONFIRMED') {
    return 'DONE';
  }
  if (value === 'CANCELED') {
    return i18n.t('quotation-status-canceled');
  }
  if (value === 'PRICING_REVIEW') {
    return i18n.t('quotation-status-pricing-review');
  }
  if (value === 'PRICING_APPROVED') {
    return i18n.t('quotation-status-pricing-approved');
  }
  return value;
};

export const formatQuotationStatusColor = (value) => {
  if (value === 'PENDING') {
    return 'grey';
  }
  if (value === 'WAITING_MANAGER_APPROVE') {
    return 'green lighten-3';
  }
  if (value === 'PRICING_APPROVED') {
    return 'green lighten-3';
  }
  if (value === 'QUOTED') {
    return 'info';
  }
  if (value === 'CONFIRMED') {
    return 'success';
  }
  if (value === 'CANCELED') {
    return 'error';
  }
  if (value === 'APPROVED') {
    return 'success';
  }
};

export const formatActionType = (item) => {
  if (item.type === 'USER_SENT_QUOTATION') {
    return i18n.t('quotation-action-log-1');
  }
  if (item.type === 'USER_RESENT_QUOTATION') {
    return i18n.t('quotation-action-log-2');
  }
  if (item.type === 'USER_CONFIRMED_QUOTATION') {
    return i18n.t('quotation-action-log-3');
  }
  if (item.type === 'USER_CANCELED_QUOTATION') {
    return i18n.t('quotation-action-log-4');
  }
  if (item.type === 'SALE_REQUEST_APPORVE_QUOTATION') {
    return i18n.t('quotation-action-log-5');
  }
  if (item.type === 'MANAGER_APPROVED_SALE_PRICING') {
    return i18n.t('quotation-action-log-6');
  }
  if (item.type === 'SYSTEM_SENT_QUOTATION') {
    return i18n.t('quotation-action-log-7');
  }
  if (item.type === 'SYSTEM_RESENT_QUOTATION') {
    return i18n.t('quotation-action-log-8');
  }
  if (item.type === 'SYSTEM_CANCELED_QUOTATION') {
    return i18n.t('quotation-action-log-9');
  }
  if (item.type === 'SYSTEM_SENT_CRO') {
    return i18n.t('quotation-action-log-10');
  }
  if (item.type === 'SYSTEM_RESENT_CRO') {
    return i18n.t('quotation-action-log-11');
  }
  if (item.type === 'USER_CONFIRMED_CRO') {
    return i18n.t('quotation-action-log-12');
  }
  if (item.type === 'SYSTEM_CANCELED_CRO') {
    return i18n.t('quotation-action-log-13');
  }
  if (item.type === 'USER_CANCELED_CRO') {
    return i18n.t('quotation-action-log-14');
  }
  if (item.type === 'USER_REQUESTED_CRO') {
    return i18n.t('quotation-action-log-15');
  }
  if (item.type === 'SYSTEM_UPDATED_QUOTATION_COMMODITY') {
    return i18n.t('quotation-action-log-16');
  }
  if (item.type === 'SYSTEM_UPDATED_QUOTATION_SURCHARGE') {
    return i18n.t('quotation-action-log-17');
  }
  if (item.type === 'SYSTEM_REVIEWING_QUOTATION_PRICE') {
    return i18n.t('quotation-action-log-18');
  }
  if (item.type === 'SYSTEM_APPROVED_QUOTATION_PRICE') {
    return i18n.t('quotation-action-log-19');
  }
  if (item.type === 'USER_APPROVED_QUOTATION') {
    return i18n.t('quotation-action-log-20');
  }
  return item.type;
};
