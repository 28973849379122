<template>
  <div class="pa-4 relative">
    <div class="overline primary--text font-weight-regular">
      Bộ lọc
      <v-icon
        small
        color="primary"
        >mdi-magnify</v-icon
      >
    </div>
    <v-progress-circular
      v-if="isLoading"
      indeterminate
      color="primary"
      style="position: absolute; top: 50%; left: 50%; z-index: 6"
    />

    <v-overlay
      absolute
      :value="true"
      color="white"
      v-if="isLoading"
    />

    <v-row>
      <v-col
        :cols="$vuetify.breakpoint.mobile ? 12 : 3"
        :class="{ 'pa-2': $vuetify.breakpoint.mobile }"
      >
        <div class="caption">POL</div>
        <PortPicker
          :value="myQuotationListQueryParams.polCode"
          class="pr-2"
          :label="$t('placeholder-pol')"
          no-self-load-data
          @change="onChangePol"
          @update-loading="setPortLoading"
          clearable
          dense
          outlined
          hide-details
        />
      </v-col>

      <v-col
        :cols="$vuetify.breakpoint.mobile ? 12 : 3"
        :class="{ 'pa-2': $vuetify.breakpoint.mobile }"
      >
        <div class="caption">POD</div>
        <PortPicker
          :label="$t('placeholder-pod')"
          @change="onChangePod"
          no-self-load-data
          solo
          clearable
          hide-details
          outlined
          dense
          :value="myQuotationListQueryParams.podCode"
        />
      </v-col>

      <v-col
        :cols="$vuetify.breakpoint.mobile ? 12 : 3"
        :class="{ 'pa-2': $vuetify.breakpoint.mobile }"
      >
        <div class="caption">POL Term</div>
        <TermPicker
          :label="$t('placeholder-pol-term')"
          no-self-load-data
          solo
          clearable
          hide-details
          outlined
          dense
          @change="onChangePolTerm"
          @update-loading="setTermLoading"
          :value="myQuotationListQueryParams.polTermCode"
        />
      </v-col>

      <v-col
        :cols="$vuetify.breakpoint.mobile ? 12 : 3"
        sm="3"
        :class="{ 'pa-2': $vuetify.breakpoint.mobile }"
      >
        <div class="caption">POD Term</div>
        <TermPicker
          solo
          no-self-load-data
          clearable
          hide-details
          outlined
          dense
          :label="$t('placeholder-pod-term')"
          @change="onChangePodTerm"
          :value="myQuotationListQueryParams.podTermCode"
        />
      </v-col>

      <v-col
        :cols="$vuetify.breakpoint.mobile ? 12 : 3"
        sm="3"
        :class="{ 'pa-2': $vuetify.breakpoint.mobile }"
      >
        <div class="caption">ETA</div>
        <v-dialog
          ref="dialog"
          v-model="etaModal"
          persistent
          width="670px"
        >
          <template v-slot:activator="{ on, attrs }">
            <BaseTextField
              :value="etaDatesText"
              :placeholder="$t('placeholder-eta')"
              prepend-inner-icon="mdi-calendar-month"
              readonly
              @click:clear="onClearEta"
              outlined
              persistent-placeholder
              dense
              hide-details
              clearable
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="eta"
            range
            :landscape="!$vuetify.breakpoint.mobile"
            full-width
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="etaModal = false"
            >
              {{ $t('button-cancel') }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="onSelectEta"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>

      <v-col
        :cols="$vuetify.breakpoint.mobile ? 12 : 3"
        sm="3"
        :class="{ 'pa-2': $vuetify.breakpoint.mobile }"
      >
        <div class="caption">{{ $t('label-created-at') }}</div>
        <v-dialog
          ref="dialog"
          v-model="dialog.createdAt"
          persistent
          width="670px"
        >
          <template v-slot:activator="{ on, attrs }">
            <BaseTextField
              :value="createdDateText"
              :placeholder="$t('placeholder-created-at')"
              prepend-inner-icon="mdi-calendar-month"
              readonly
              persistent-placeholder
              solo
              hide-details
              @click:clear="onClearCreatedDate"
              clearable
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="createdDate"
            range
            :landscape="!$vuetify.breakpoint.mobile"
            full-width
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="dialog.createdAt = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="onSelectCreatedDate"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>

      <v-col
        :cols="$vuetify.breakpoint.mobile ? 12 : 3"
        sm="3"
        :class="{ 'pa-2': $vuetify.breakpoint.mobile }"
      >
        <div class="caption">{{ $t('label-status') }}</div>

        <v-select
          :v-model="status"
          :value="status"
          :items="statusOptions"
          :label="$t('placeholder-status')"
          prepend-inner-icon="mdi-pen-plus"
          @change="onChangeStatus"
          item-text="text"
          item-value="value"
          solo
          clearable
          hide-details
          outlined
          dense
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import PortPicker from '@/components/shared/local/vsl/PortPicker';
import VesselPicker from '@/components/shared/local/vsl/VesselPicker';
import VoyagePicker from '@/components/shared/local/vsl/VoyagePicker';
import TermPicker from '@/components/shared/local/vsl/TermPicker';
import BaseTextField from '@/components/shared/local/base/BaseTextField';
import dayjs from 'dayjs';
import { QuotationStatus } from '@/constants/status.constant';
export default {
  data: () => ({
    statusOptions: Object.keys(QuotationStatus).map((key) => ({
      value: key,
      text: QuotationStatus[key],
    })),
    status: undefined,
    eta: [],
    selectedEta: [],
    createdDate: [],
    dialog: {
      createdAt: false,
    },
    etaModal: false,
    isLoading: false,
  }),

  computed: {
    ...mapState('vsl/quotation', [
      'myQuotationList',
      'myQuotationListQueryParams',
    ]),

    etaDatesText() {
      return this.convertDates(this.eta).join(' ~ ');
    },

    createdDateText() {
      return this.convertDates(this.createdDate).join(' ~ ');
    },
  },
  components: {
    PortPicker,
    VesselPicker,
    VoyagePicker,
    TermPicker,
    BaseTextField,
  },
  async created() {
    const parsedQS = this.$route.query;
    this.eta = [parsedQS.from_eta, parsedQS.to_eta].filter(
      (item) => item !== undefined
    );

    this.createdDate = [
      parsedQS.from_created_at,
      parsedQS.to_created_at,
    ].filter((item) => item !== undefined);

    this.status = parsedQS.status;

    await this.loadFilter();
  },
  methods: {
    ...mapMutations('vsl/quotation', ['SET_LIST_QUERY_PARAMS']),
    ...mapActions('vsl/quotation', ['getQuotationList', 'setListQueryParams']),
    ...mapActions('vsl/port', ['fetchPortList', 'clearPortList']),
    ...mapActions('vsl/term', ['fetchTermList', 'clearTermList']),

    convertDates(dates) {
      let newDates = dates;
      if (dates.length > 1) {
        if (dayjs(dates[0]).isAfter(dayjs(dates[1]))) {
          newDates = [dates[1], dates[0]];
        }
      }
      return newDates;
    },
    getStatus(status) {
      switch (status) {
        case QuotationStatus.DRAFT:
          return 'Khởi tạo';
        case QuotationStatus.PENDING:
          return 'Chờ báo giá';
        case QuotationStatus.WAITING_MANAGER_APPROVE:
          return 'Chờ duyệt giá';
        case QuotationStatus.QUOTED:
          return 'Đã báo giá';
        case QuotationStatus.CONFIRMED:
          return 'DONE';
        case QuotationStatus.CANCELED:
          return 'Đã hủy';
        case QuotationStatus.PRICING_APPROVED:
          return 'Đã duyệt giá';
        case QuotationStatus.PRICING_REVIEW:
          return 'Chờ duyệt giá';
        case QuotationStatus.APPROVED:
          return 'APPROVED';
        default:
          return status;
      }
    },
    onChangePol(pol) {
      this.SET_LIST_QUERY_PARAMS({
        page: 0,
        polCode: pol?.code,
      });
    },
    onChangePod(pod) {
      this.SET_LIST_QUERY_PARAMS({
        page: 0,
        podCode: pod?.code,
      });
    },
    onChangePolTerm(pol) {
      this.SET_LIST_QUERY_PARAMS({
        page: 0,
        polTermCode: pol?.code,
      });
    },
    onChangePodTerm(pod) {
      this.SET_LIST_QUERY_PARAMS({
        page: 0,
        podTermCode: pod?.code,
      });
    },
    onChangeVessel(vessel) {
      this.SET_LIST_QUERY_PARAMS({
        page: 0,
        vesselCode: vessel?.code,
      });
    },
    onChangeVoyage(voyage) {
      this.SET_LIST_QUERY_PARAMS({
        page: 0,
        voyageCode: voyage?.code,
      });
    },
    onSelectEta() {
      this.etaModal = false;

      this.SET_LIST_QUERY_PARAMS({
        page: 0,
        fromEta: this.eta[0],
        toEta: this.eta[1],
      });
    },

    onSelectCreatedDate() {
      this.dialog.createdAt = false;
      let newDates = this.convertDates(this.createdDate);
      this.SET_LIST_QUERY_PARAMS({
        page: 0,
        fromCreatedAt: newDates[0],
        toCreatedAt: newDates[1],
      });
    },
    onChangeStatus(value) {
      this.SET_LIST_QUERY_PARAMS({
        page: 0,
        status: value || undefined,
      });
    },

    onClearCreatedDate() {
      this.createdDate = [];
      this.SET_LIST_QUERY_PARAMS({
        page: 0,
        fromCreatedAt: undefined,
        toCreatedAt: undefined,
      });
    },

    onClearEta() {
      this.eta = [];
      this.SET_LIST_QUERY_PARAMS({
        page: 0,
        fromEta: undefined,
        toEta: undefined,
      });
    },

    setPortLoading(bool) {
      this.isLoadingPort = bool;
    },

    setTermLoading(bool) {
      this.isLoadingTerm = bool;
    },
    async loadFilter() {
      try {
        this.isLoading = true;
        this.$emit('set-loading', true);
        await this.fetchPortList();
        await this.fetchTermList();
      } finally {
        this.isLoading = false;
        this.$emit('set-loading', false);
      }
    },
    clearFilter() {
      this.clearPortList();
      this.clearVesselList();
    },
  },
  watch: {
    myQuotationListQueryParams: {
      handler(value) {
        this.eta = [value.fromEta, value.toEta].filter(
          (item) => item !== undefined
        );

        this.createdDate = [value.fromCreatedAt, value.toCreatedAt].filter(
          (item) => item !== undefined
        );

        this.status = value.status;
      },
    },
  },
};
</script>

<style></style>
