<template>
  <v-autocomplete
    :value="value"
    :items="list"
    :loading="isLoading"
    :disabled="isLoading"
    :filter="customFilter"
    type="text"
    prepend-inner-icon="mdi-map-marker"
    item-text="name"
    item-value="code"
    return-object
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:label>
      <span
        v-if="required"
        class="red--text"
      >
        <strong>* </strong>
      </span>
      {{ label }}
    </template>
  </v-autocomplete>
</template>

<script>
import { removeAccents, removeSpecialCharaters } from '@/utils/string.util';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  components: {},
  props: {
    value: {
      type: String,
    },
    autoSelectFirst: {
      type: Boolean,
      default: false,
      required: false,
    },
    noSelfLoadData: {
      type: Boolean,
      default: false,
      required: false,
    },
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    isLoadingTerm: {
      type: Boolean,
    },
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    ...mapState('vsl/term', { list: 'termList' }),
  },
  async created() {
    try {
      if (!this.noSelfLoadData) {
        this.isLoading = true;
        await this.fetchList();
      }
      if (!this.value && this.autoSelectFirst) {
        this.$emit('change', this.list[0]);
      }
    } finally {
      this.isLoading = false;
    }
  },
  watch: {},
  methods: {
    ...mapActions('vsl/term', { fetchList: 'fetchTermList' }),
    customFilter(item, queryText, itemText) {
      const textOne = removeSpecialCharaters(
        removeAccents(item.name.toLowerCase())
      );
      const searchText = removeSpecialCharaters(
        removeAccents(queryText.toLowerCase())
      );

      return textOne.indexOf(searchText) > -1;
    },
  },
};
</script>

<style></style>
