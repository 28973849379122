export const OrderActionTypes = {
  ORDER_DRAFTED: 'ORDER_DRAFTED',
  ORDER_SUBMITTED: 'ORDER_SUBMITTED',
  ORDER_APPROVED: 'ORDER_APPROVED',
  DRIVER_CHECKED_IN: 'DRIVER_CHECKED_IN',
  DRIVER_ENTERED_WAREHOUSE: 'DRIVER_ENTERED_WAREHOUSE',
  DRIVER_CHECKED_OUT: 'DRIVER_CHECKED_OUT',
  DRIVER_SELF_CHECKED_IN: 'DRIVER_SELF_CHECKED_IN',
  DRIVER_SELF_CHECKED_OUT: 'DRIVER_SELF_CHECKED_OUT',
  CUSTOMER_CREATED_SHIPMENT: 'CUSTOMER_CREATED_SHIPMENT',
  CUSTOMER_UPDATED_SHIPMENT: 'CUSTOMER_UPDATED_SHIPMENT',
  CUSTOMER_DELETED_SHIPMENT: 'CUSTOMER_DELETED_SHIPMENT',
  SYSTEM_CREATED_SHIPMENT: 'SYSTEM_CREATED_SHIPMENT',
  SYSTEM_UPDATED_SHIPMENT: 'SYSTEM_UPDATED_SHIPMENT',
  SYSTEM_DELETED_SHIPMENT: 'SYSTEM_DELETED_SHIPMENT',
  CUSTOMER_REQUESTED_SHIPMENT: 'CUSTOMER_REQUESTED_SHIPMENT',
  SYSTEM_APPROVED_SHIPMENT: 'SYSTEM_APPROVED_SHIPMENT',
};

export const OrderStatus = {
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  CANCELED: 'CANCELED',
};

export const OrderShipmentStatus = {
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  CHECKED_IN: 'CHECKED_IN',
  CHECKED_OUT: 'CHECKED_OUT',
  ENTERED_WAREHOUSE: 'ENTERED_WAREHOUSE',
};

export const OrderShipmentFileTypes = {
  DEFAULT_FILE: 'DEFAULT_FILE',
  DRIVER_REGISTRATION_FILE: 'DRIVER_REGISTRATION_FILE',
  GATE_CHECK_IN_FILE: 'GATE_CHECK_IN_FILE',
  GATE_CHECK_OUT_FILE: 'GATE_CHECK_OUT_FILE',
  WAREHOUSE_FILE: 'WAREHOUSE_FILE',
};

export const QuotationStatus = Object.freeze({
  DRAFT: 'Khởi tạo',
  PENDING: 'Chờ báo giá',
  QUOTED: 'Chờ duyệt giá',
  CONFIRMED: 'Đã báo giá',
  CANCELED: 'Đã hủy',
  PRICING_REVIEW: 'Đã duyệt giá',
  PRICING_APPROVED: 'Chờ duyệt giá',
  APPROVED: 'APPROVED',
});
